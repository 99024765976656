import React, { Component, useState, useEffect, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Card from '../components/card'
import styled from 'styled-components'
import ServiceHero from '../components/service-hero'
import CTA from '../components/cta-bar'
import PhotoGalleryComponent from '../components/photo-gallery-component'
import AwardsCertsSlider from '../components/awards-certs'
import FeaturedProductBadges from '../components/featured-product-badges'
import ReactCompareImage from '../components/before-after-image'
import ReviewSliderFullWidth from '../components/reviews-slider-fullwidth'
import Slider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { OPEN_CONTACT_MODAL } from '../consts'
import { useTextReplacement } from '../hooks/useTextReplacement'
import Popup from 'reactjs-popup'
import ContactForm from '../components/estimate-form'
import { X, ChevronLeft, ChevronRight } from 'react-feather'
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'
import ReviewsAllModalPopup from '../components/reviews-all-modal-popup'
import Button from '../components/button'

import { AreasServed } from '../components/areas-served'
import parse, { domToReact } from 'html-react-parser'

import { TestimonialSection } from '../components/testimonial_video'
import BlogPostItem from '../components/blog-post-item'
import { getSchemaSameAs } from '../helpers/schema-json'
import ReviewTilesComponent from '../components/ReviewTilesComponent'

const ServiceTemplate = (props) => {
  const pageData = props.data.contentfulService
  const { citiesList } = props.data
  const { testimonials: testimonial_video_posts } =
    pageData.testimonialSection || { testimonials: [] }
  const { showAreasServed } = pageData

  const MainContentContainer = styled.div``

  const SidebarContainer = styled.div``

  const WhiteBGSection = styled.section`
    background: #f9f9f9;
    padding: 2.5em 1em;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px 0px;
  `
  const SectionHeading = styled.h2`
    text-align: center;
    max-width: 767px;
    margin: 0 auto;
    margin-bottom: 0.35em;

    font-size: calc(0.75vw + 1.2em);
  `
  const SectionDescription = styled.p`
    max-width: 767px;
    color: #555;
    margin: 0 auto;
    margin-bottom: 1.5em;
    text-align: center;
  `

  const ReputationAwardsBGSection = styled.section`
    padding: 2.5em 1em;
    background: #fff;
    .container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      & > div {
        &: first - child {
          flex: 0 0 60 %;
          width: 60 %;
        }
        flex: 1;
        padding: 1 % 3 %;
        @media (max - width: 767px) {
          &: first - child {
            flex: 0 0 100 %;
            width: 100 %;
          }
          flex: 0 0 100 %;
          width: 100 %;
          padding: 2 % 0;
        }
      }
    }
  `

  const StyledSlider = ({ photoSlider }) => {
    const SliderContainer = styled(Slider)`
      height: 100 %;
      display: block;

      .slick - list,
      .slick - track {
        height: 100 %;
        display: block;
      }
      &: hover {
        .PrevNextArrowsContainer {
          opacity: 1 !important;
        }
      }
      .PrevNextArrowsContainer {
        opacity: 0.1;
        background-color: rgba(204, 204, 204, 0.4);
        &: hover {
          background-color: rgba(204, 204, 204, 0.9);
        }
      }
    `
    const PrevNextArrowsContainer = styled.div`
      position: absolute;
      z-index: 1;
      @media (max - width: 767px) {
        top: calc(50 % - 15px);
        height: 30px;
        width: 30px;
        padding: 5px;
      }
      top: calc(50 % - 25px);
      height: 50px;
      width: 50px;

      transition: 0.2s ease all;
      color: #333;
      border-radius: 50 %;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      justify-content: center;
      &.carousel - icon - right {
        right: 18px;
      }
      &.carousel - icon - left {
        left: 18px;
      }
    `

    const NextArrow = (props) => {
      const { onClick } = props

      //auto scroll
      //setInterval(() => onClick(), 10000)

      return (
        <PrevNextArrowsContainer
          className="icon-container carousel-icon-left PrevNextArrowsContainer"
          onClick={onClick}
        >
          <ChevronLeft size={26} />
        </PrevNextArrowsContainer>
      )
    }

    const PrevArrow = (props) => {
      const { onClick } = props
      return (
        <PrevNextArrowsContainer
          className="icon-container carousel-icon-right PrevNextArrowsContainer"
          onClick={onClick}
        >
          <ChevronRight size={26} />
        </PrevNextArrowsContainer>
      )
    }

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
      nextArrow: <PrevArrow />,
      prevArrow: <NextArrow />,
    }

    return (
      <SliderContainer {...settings} className="relative">
        {photoSlider.map((item) => (
          <GatsbyImage
            image={getImage(item)}
            key={item.id}
            className="shadow-md"
          />
        ))}
      </SliderContainer>
    )
  }

  const options = {
    replace: ({ attribs, children, name, type, ...theRest }) => {
      if (!attribs) return
      if (
        type == 'tag' &&
        name == 'a' &&
        attribs.class.includes(OPEN_CONTACT_MODAL)
      ) {
        const ATagButton = ({ onClick }) => {
          return (
            <a
              style={{ borderWidth: '0' }}
              href={attribs.href}
              className="border-radius button-style-primary button-style-white-outline border-0 cursor-pointer primary cta-button"
              onClick={(e) => {
                e.preventDefault()
                onClick(e)
              }}
            >
              {domToReact(children, options)}
            </a>
          )
        }
        return (
          <Popup trigger={<ATagButton />} modal closeOnDocumentClick>
            {(close) => (
              <div className="p-2 block">
                <button
                  style={{ borderWidth: '0', float: 'right' }}
                  className="cursor-pointer d-block border-radius mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                  onClick={() => {
                    close()
                  }}
                >
                  <X color={'#333'} strokeWidth={'3'} className="d-block" />
                </button>
                <div className="p-4 py-4 block">
                  <h2
                    className="mb-1 popupheading"
                    style={{ lineHeight: '1.05' }}
                  >
                    Contact Us
                  </h2>
                  <p
                    style={{
                      color: '#666',
                      lineHeight: '1.5',
                      fontSize: '.9em',
                    }}
                    className="mb-3 popupdesc"
                  >
                    Interested in learning more about our new home construction
                    process? We're here to help. Simply give us a call or fill
                    out this form on our website and we'll take the time to
                    learn all of the details of your dream home.
                  </p>
                  <ContactForm
                    showServicesList={true}
                    showLabels={true}
                    showPlaceHolders={true}
                    pageLocation={'Not provided'}
                  />
                </div>
              </div>
            )}
          </Popup>
        )
      }
    },
  }

  const TopBar = () => {
    //pageData has the data
    const topBar = (pageData && pageData.topbar) || []
    return topBar.map(({ id, title, cssClasses, content }) => (
      <section className={cssClasses}>
        {parse(content.content, options)}
      </section>
    ))
  }

  const SidebarColumn = () => {
    const sideBarCards = pageData.sidebar.map((sidebar, index) => {
      if (sidebar.__typename == 'ContentfulSidebar') {
        return <Card key={index} sidebar={sidebar} />
      } else if (sidebar.__typename == 'ContentfulSidebarCollection') {
        const innerCards = sidebar.sidebars.map((sidebar, index) => {
          return <Card key={'inner' + index} sidebar={sidebar} />
        })
        return innerCards
      } else return null
    })

    return sideBarCards
  }

  const FooterTestimonials = () => {
    const footerTestimonialComponent = pageData.sidebar.map(
      (sidebar, index) => {
        if (sidebar.__typename == 'ContentfulSidebar') {
          if (sidebar.content.content.includes('{{Testimonials}}')) {
            return <ReviewSliderFullWidth renderTestimonialsSidebar={sidebar} />
          }
        } else if (sidebar.__typename == 'ContentfulSidebarCollection') {
          const innerCards = sidebar.sidebars.map((sidebar, index) => {
            if (sidebar.content.content.includes('{{Testimonials}}')) {
              return (
                <ReviewSliderFullWidth renderTestimonialsSidebar={sidebar} />
              )
            }
          })
          return innerCards
        } else return null
      }
    )

    return footerTestimonialComponent
  }

  const personalizedTextRef = useTextReplacement()
  const personalizedTextRefTwo = useTextReplacement()

  const [betaFeaturesEnabled, setBetaFeaturesEnabled] = useState(
    JSON.parse(
      typeof window !== 'undefined' &&
        window.localStorage &&
        window.localStorage.getItem('BETA_FEATURES')
    ) || false
  )
  const schemaSameAs = getSchemaSameAs(props.data.site.siteMetadata.social)

  return (
    <>
      <Layout
        pageProps={props}
        footerLogoShowcase={pageData.footerLogoShowcase}
        customSchema
        headerTransparent={true}
      >
        <SEO
          title={pageData.seoTitle}
          description={pageData.metaDescription}
          img={
            pageData.heroImage &&
            pageData.heroImage.gatsbyImageData &&
            pageData.heroImage.gatsbyImageData.images.fallback &&
            pageData.heroImage.gatsbyImageData.images.fallback.src
          }
          robots={pageData.metaRobotsContent}
        />

        <ServiceHero
          pageData={pageData}
          serviceTitle={pageData.serviceTitle}
          heroImage={pageData.heroImage}
          excerpt={pageData.excerpt}
          offerDisclaimer={
            pageData.offerDisclaimer ? pageData.offerDisclaimer : ''
          }
          specialOffer={pageData.specialOffer}
          pageProps={props}
          badges={pageData.featuredBadges}
          headerTransparent={true}
        />

        {pageData.socialReviewsFullWidth &&
        pageData.socialReviewsFullWidth.slug ? (
          <SocialReviewsFullwidth
            modalLinkTo={`/reviews/${pageData.socialReviewsFullWidth.slug}/`}
          />
        ) : null}
        <section className="bg-white relative shadow-sm py-4">
          <ReviewTilesComponent />
        </section>
        {pageData &&
        pageData.photoGallery &&
        pageData.photoGallery[0].photos ? (
          <PhotoGalleryComponent gallery={pageData.photoGallery} />
        ) : null}

        {pageData && pageData.showBeforeAfterPhotos ? (
          <div className="beforeaftersection">
            <div className="container flex flex-wrap justify-center">
              <div className="flex flex-col justify-center lg:w-1/3 lg:pr-4 w-full text-center lg:text-left lg:items-start">
                <p className="font-semibold m-0 p-0 text-gvd-500 text-lg">
                  Before &amp; After Transformations
                </p>
                <h3 className="my-2 font-bold text-2xl leading-tight">
                  Check Out Our Work
                  {/* {pageData.serviceTitle} Before &amp; After */}
                </h3>
                <p className="text-gray-600">
                  {pageData.beforeAfterDescription}
                </p>
                {pageData.beforeafterPhotoGallery ? (
                  <Link
                    className="button-style-primary button-style-primary "
                    to={`/photo-gallery/${pageData.beforeafterPhotoGallery.slug}`}
                    state={{
                      modal: true,
                    }}
                  >
                    <span className="flex items-center justify-center babtn">
                      See More Before &amp; After Photos <ChevronRight />
                    </span>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        <div>
          <div className="flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto py-8 md:py-12">
            <div
              className={`${
                pageData.sidebar ? 'md:w-2/3 md:pr-4' : 'w-full'
              } prose md:prose-lg`}
            >
              {pageData && pageData.content && pageData.content.content ? (
                <div
                  ref={personalizedTextRef}
                  dangerouslySetInnerHTML={{ __html: pageData.content.content }}
                />
              ) : (
                ''
              )}
            </div>

            {pageData.sidebar ? (
              <div className="w-full md:w-1/3 md:pl-4 space-y-4 md:space-y-6">
                <SidebarColumn />
                {props.data.serviceRelatedBlogPosts &&
                  props.data.serviceRelatedBlogPosts.edges.length > 0 && (
                    <Card
                      sidebar={{
                        title: 'From Our Blog',
                        content: { content: 'blog' },
                      }}
                    >
                      {props.data.serviceRelatedBlogPosts.edges.map(
                        ({ node }) => (
                          <BlogPostSideBarItem
                            {...node}
                            key={node.id}
                            author={node.author.name}
                            className="mb-3"
                          />
                        )
                      )}
                    </Card>
                  )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <FooterTestimonials />
        {/* {showAreasServed && (
          <AreasServed
            citiesList={citiesList.edges}
            serviceName={pageData.serviceTitle}
            parentPageSlug={pageData.slug}
          />
        )} */}
        <CTA
          title={'Start Your ' + pageData.serviceTitle + ' Project Today!'}
          customTitle={pageData.footerCallToActionHeading}
          customDesc={pageData.footerCallToActionDesc}
          bgimage={pageData.footerCallToActionImg}
          serviceHeroImage={pageData.heroImage}
          btn={'Get Started!'}
          props={props}
        />
      </Layout>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
        {
          "@id": "https://ky-pd.com#HomeAndConstructionBusiness",
          "@context": "https://www.schema.org",
          "@type": "HomeAndConstructionBusiness",
          "name": "KYPD Kentucky Plumbing",
          "url": "https://ky-pd.com/",
          "logo": "https://images.ctfassets.net/6c4iaavulnmy/TJqEt5ulPazetGtCkjwpm/3246ec7f0caede20b817352e0a811ea1/grimebusters-thumb3.jpg",
          "image": "https://images.ctfassets.net/6c4iaavulnmy/TJqEt5ulPazetGtCkjwpm/3246ec7f0caede20b817352e0a811ea1/grimebusters-thumb3.jpg",
          "description": "KYPD Kentucky Plumbing is a ${pageData.serviceTitle
            .replace(/{{city}}/g, '')
            .replace(' in ', '')
            .replace(
              ', CA',
              ''
            )} specializing in residential and commerical plumbing near you.",
          "telephone": "+1 859-695-3927",
          "priceRange":"$$",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "101 Virginia Ln",
            "addressLocality": "Nicholasville",
            "addressRegion": "KY",
            "postalCode": "40356",
            "addressCountry": "US"
        },
         
            "openingHours": "Mo, Tu, We, Th, Fr 08:00-18:00",
          "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "sales",
              "telephone": "+1 859-695-3927"
          }
      }`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
      {
        "@context": "http://schema.org/",
        "@type": "service",
        "servicetype": "${pageData.serviceTitle}",
        "provider": {
            "@type": "organization",
            "name": "${props.data.site.siteMetadata.title}",
            "url": "${
              props.data.site.siteMetadata.siteUrl + props.location.pathname
            }",
            "logo": "https://images.ctfassets.net/6c4iaavulnmy/TJqEt5ulPazetGtCkjwpm/3246ec7f0caede20b817352e0a811ea1/grimebusters-thumb3.jpg",
            "image": "https://images.ctfassets.net/6c4iaavulnmy/TJqEt5ulPazetGtCkjwpm/3246ec7f0caede20b817352e0a811ea1/grimebusters-thumb3.jpg",
            "address": {
                "@type": "postaladdress",
                "addresslocality": "Lexington",
                "addressregion": "KY"
            },
            "contactpoint": {
                "@type": "contactpoint",
                "contacttype": "customer support",
                "telephone": "${
                  props.data.site.siteMetadata.companyInfo.phone
                }",
                "email": "${props.data.site.siteMetadata.companyInfo.email}"
            },
            "sameas": [${schemaSameAs.map((link) => `"${link}"`)}]
        },
        "areaserved": [{
            "@type": "city",
            "name": "Lexington",
            "@id": "https://en.wikipedia.org/wiki/Lexington,_Kentucky"
        }],
        "hasoffercatalog": {
          "@type": "offercatalog",
          "name": "${pageData.serviceTitle}",
          "itemlistelement": [{
              "@type": "offercatalog",
              "name": "${pageData.serviceTitle.toLowerCase()} services",
              "itemlistelement": [
                ${(pageData.schemaItemOffered || []).map(
                  (item) =>
                    `{
                "@type": "offer",
                "itemoffered": {
                    "@type": "service",
                    "name": "${item}"
                }
            }`
                )}]
          }]
      }
  }
`,
        }}
      />
    </>
  )
}
const BlogPostSideBarItem = ({
  title,
  slug,
  image,
  publishDate,
  author,
  page = 'blog',
  excerpt,
  className,
}) => {
  return (
    <Link
      to={'/' + page + '/' + slug}
      className="flex mb-4 space-x-4"
      title={title}
    >
      <div className="w-24 h-24 flex-basis">
        <GatsbyImage
          image={getImage(image)}
          alt={title}
          className="rounded-lg"
        />
      </div>
      <div className="flex-1">
        <p className="pb-1 border-b block hover:text-brand-600 flex font-semibold items-top text-sm mb-0 leading-tight text-custom-gray">
          {title}
        </p>
        <p className="text-xs text-gray-500 mb-0">
          {publishDate} by {author}{' '}
        </p>
      </div>
    </Link>
  )
}

export default ServiceTemplate

export const pageQuery = graphql`
  query serviceQuery(
    $slug: String!
    $serviceID: String
    $categorySlug: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
        companyInfo {
          phone
          email
        }
        social {
          guildquality
          facebook
          yelp
          instagram
          youtube
          google
          houzz
        }
      }
    }
    contentfulTestimonialCategory(
      service: { elemMatch: { id: { eq: $serviceID } } }
    ) {
      id
    }
    contentfulService(slug: { eq: $slug }) {
      id
      serviceTitle
      seoTitle
      metaDescription
      metaRobotsContent
      slug
      excerpt
      offerDisclaimer
      sliderHeading
      sliderSubHeading
      specialOffer
      socialReviewsFullWidth {
        id
        slug
      }
      testimonialSection {
        testimonials {
          id
          title
          projectReference {
            slug
          }
          video {
            id
            title
            file {
              url
              contentType
            }
          }
          thumbnail {
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }

      showFeaturedBenefitsSection
      beforeAfterDescription
      showBeforeAfterPhotos
      content {
        content
      }
      beforeafterPhotoGallery {
        slug
      }
      photoGallery {
        id
        title
        description {
          description
        }
        photos {
          id
          title
          gatsbyImageData(
            width: 150
            quality: 70
            aspectRatio: 1.26153846154
            placeholder: NONE
          )
          fullSize: gatsbyImageData(width: 1874, quality: 70, placeholder: NONE)
        }
      }
      featuredBenefits {
        featuredBenefits
      }
      photoSlider {
        id
        photos {
          id
          gatsbyImageData(width: 220, quality: 80, placeholder: NONE)
          smallerFluid: gatsbyImageData(
            width: 220
            quality: 80
            placeholder: NONE
          )
          fluid: gatsbyImageData(width: 1000, quality: 100, placeholder: NONE)
        }
      }
      featuredBadges {
        title
        gatsbyImageData(width: 300, placeholder: NONE)
      }
      footerCallToActionHeading
      footerLogoShowcase
      footerCallToActionDesc
      footerCallToActionImg {
        gatsbyImageData(width: 1800, placeholder: NONE)
      }
      heroImage {
        gatsbyImageData(width: 1200, quality: 70, placeholder: BLURRED)
      }
      callToAction

      sidebar {
        __typename
        ... on ContentfulSidebar {
          id
          title
          content {
            id
            content
          }
          testimonialCategory {
            id
            name
          }
          cssClasses
        }
        ... on ContentfulSidebarCollection {
          sidebars {
            id
            title
            content {
              id
              content
            }
            testimonialCategory {
              id
              name
            }
            cssClasses
          }
        }
      }
      topbar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
      showAreasServed
    }
    citiesList: allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          slug
          zipCodes
        }
      }
    }
    serviceRelatedBlogPosts: allContentfulBlogPost(
      filter: { category: { slug: { eq: $categorySlug } } }
      limit: 4
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          # tags
          image: heroImage {
            gatsbyImageData(
              height: 120
              width: 120
              aspectRatio: 1
              resizingBehavior: CROP
              cropFocus: CENTER
              quality: 70
              placeholder: NONE
            )
          }
          metaDescription
          author {
            name
          }
        }
      }
    }
  }
`
